<template>
    <b-card no-body>
        <b-card-body
            :class="clickable ? { clickable: true } : ''"
            @click="
                clickable
                    ? $router.push({
                          name: 'HackathonView',
                          params: {
                              hackathon_id: id,
                          },
                      })
                    : null
            "
        >
            <b-row>
                <b-col>
                    <h3>
                        <a
                            href="javascript:void(0)"
                            @click="
                                $router.push({
                                    name: 'HackathonView',
                                    params: {
                                        hackathon_id: id,
                                    },
                                })
                            "
                        >
                            {{ name }}
                        </a>
                        &nbsp;

                        <a
                            @click.stop="
                                $router.push({
                                    name: 'HackathonTimeline',
                                    params: {
                                        hackathon_id: id,
                                    },
                                })
                            "
                            v-if="is_creator"
                            href="javascript:void(0)"
                        >
                            <i class="fas fa-edit"></i>
                        </a>
                    </h3>
                </b-col>
                <b-col
                    sm="auto"
                    class="pr-1 pl-0"
                    v-if="is_participant || is_creator || is_manager"
                >
                    <el-tooltip
                        v-if="hackathon_is_active(id)"
                        content="This hackathon is currently active in the sidebar"
                        placement="top"
                    >
                        <badge :rounded="true" size="md" type="primary">
                            Active
                        </badge>
                    </el-tooltip>

                    <el-tooltip
                        content="Makes this hackathon active in the sidebar"
                        placement="top"
                        v-else
                    >
                        <base-button
                            type="primary"
                            :pill="true"
                            size="sm"
                            @click.stop="activate_hackathon()"
                            >Activate
                        </base-button>
                    </el-tooltip>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p>{{ description }}</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h6 class="mb-0 text-muted" v-if="date_in_future(starts)">
                        Starts: {{ starts.substring(0, 10) }}
                    </h6>
                    <h6 class="mb-0 text-muted" v-if="organization">
                        By: {{ organization }}
                    </h6>
                </b-col>
                <b-col class="text-right">
                    <badge :rounded="true" size="md" type="info" v-if="phase">
                        {{ phase }}
                    </badge>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
export default {
    name: "ListHackathonCard",
    props: {
        id: {
            type: String,
            description: "The id of the hackathon",
            default: null,
        },
        name: {
            type: String,
            description: "The name of the hackathon",
            default: null,
        },
        phase: {
            type: String,
            description: "The phase of the hackathon",
            default: null,
        },
        description: {
            type: String,
            description: "The short_description of the hackathon",
            default: null,
        },
        starts: {
            type: String,
            description: "Starting Datetime",
            default: null,
        },
        is_manager: {
            type: Boolean,
            description: "Is a manager of this hackathon",
            default: false,
        },
        is_creator: {
            type: Boolean,
            description: "Is a creator of this hackathon",
            default: false,
        },
        is_participant: {
            type: Boolean,
            description: "Is a participant of this hackathon",
            default: false,
        },
        organization: {
            type: String,
            description: "The organization name of the hackathon.",
            default: "",
        },
        clickable: {
            type: Boolean,
            description: "Clicking on the card takes you to the hackahton page",
            default: false,
        },
    },
    data() {
        return {};
    },
    methods: {
        date_in_future(in_date) {
            let date = new Date(in_date);
            let current_date = new Date();
            return date > current_date;
        },

        hackathon_is_active(hackathon_id) {
            return hackathon_id == store.getters.getActiveHackathon.id;
        },

        activate_hackathon() {
            store.commit("setActiveHackathon", {id: this.id, name: this.name});
            global_event_emitter.$emit("hackathon_sidebar_reload_required");
        },
    },
    mounted() {},
};
</script>

<style></style>
